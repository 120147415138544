const wrapperStyles = {
  position: "relative",
  height: "100vh",
  display: "flex",
  "flex-direction": "column",
  "align-items": "center",
  "justify-content": "center",
  background: "lightpink",
  overflow: "hidden",
};

const Page1 = () => {
  return (
    <div style={wrapperStyles}>
      <h1>Heyyy</h1>
      <h1>IT IS YOUR B'DAAYYYYYYYY 🔥🎊</h1>
      <img
        src="https://i.giphy.com/g5R9dok94mrIvplmZd.webp"
        alt="party"
      />
    </div>
  );
};

export default Page1;
