const wrapperStyles = {
  position: "relative",
  height: "100vh",
  display: "flex",
  "flex-direction": "column",
  "align-items": "center",
  "justify-content": "center",
  background: "lightpink",
  overflow: "hidden",
};

const Page2 = () => {
  return (
    <div style={wrapperStyles}>
      <h1>I really hope you get everything you desire this year</h1>
      <h1>That you achieve everything you dream of</h1>
      <hr />
      <h1>And remember, you'll always find me by your side 🥰</h1>
      <br />
      <br />
      <br />
      <br />
      <h1>Happy Birthday again, Manasi ❤️</h1>
    </div>
  );
};

export default Page2;
