import { useEffect } from "react";

import HBDManasi from "./happybdaymanasi";
import Nav from "./nav";
import Page1 from "./page1";
import Page2 from "./page2";

import "./App.css";

const randCol = () =>
  "#" + (((1 << 24) * Math.random()) | 0).toString(16).padStart(6, "0");

function App() {
  useEffect(() => {
    const body = document.querySelector("body");
    const interval = setInterval(() => {
      body.setAttribute("style", `--random-color: ${randCol()}`);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Nav />
      <HBDManasi />
      <Page1 />
      <Page2 />
    </>
  );
}

export default App;
