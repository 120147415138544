import { useEffect, useState } from "react";

import BigText from "./bigtext";
import Funky from "./funky";
import texts from "./texts";

import Balloons1 from "./assets/balloons-2.webp";

const wrapperStyles = {
  position: "relative",
  height: "100vh",
  display: "flex",
  "flex-direction": "column",
  "align-items": "center",
  "justify-content": "center",
  background: "lightpink",
  overflow: "hidden",
};

const balloonStylesPrimitive = {
  position: "absolute",
  width: "100px",
  top: "calc(100% - 10rem)",
  transform: "scale(0.5)",
  transition: "all 2s ease",
};
const balloonStyles1 = {
  ...balloonStylesPrimitive,
  left: 0,
};
const balloonStyles2 = {
  ...balloonStylesPrimitive,
  right: 0,
};

const emojiStyles = [
  {
    position: "absolute",
    transform: "rotate(30deg) scale(5) translateY(2rem)",
    right: "2rem",
  },
  {
    position: "absolute",
    transform: "rotate(-30deg) scale(5) translateY(2rem)",
    left: "2rem",
  },
];

function HBDManasi() {
  const [b1, setB1] = useState(balloonStyles1);
  const [b2, setB2] = useState(balloonStyles2);
  const [b3, setB3] = useState(balloonStyles1);
  const [b4, setB4] = useState(balloonStyles2);

  useEffect(() => {
    setTimeout(() => {
      setB1((b) => {
        const B = { ...b, top: 0, transform: "scale(1)" };
        return B;
      });
      setB2((b) => {
        const B = { ...b, top: 0, transform: "scale(1)" };
        return B;
      });
      setB3((b) => {
        const B = { ...b, transform: "scale(1)" };
        return B;
      });
      setB4((b) => {
        const B = { ...b, transform: "scale(1)" };
        return B;
      });
    }, 1000);
  }, []);

  return (
    <>
      <div style={wrapperStyles}>
        <>{BigText(Funky(texts.mainPageHeading1))}</>
        <>{BigText(Funky(texts.mainPageHeading2))}</>
        <img src={Balloons1} alt="balloons" style={b1} />
        <img src={Balloons1} alt="balloons" style={b2} />
        <img src={Balloons1} alt="balloons" style={b3} />
        <img src={Balloons1} alt="balloons" style={b4} />
        <span style={emojiStyles[0]}>😍</span>
        <span style={emojiStyles[1]}>🥳</span>
      </div>
    </>
  );
}

export default HBDManasi;
